import get from "lodash/get";
import { GenericObject } from "src/defs";
import {
  ServiceLineObject,
  CanvasInformationObject,
} from "src/context/canvas/types";
import { CLAIM_ACTION_STATUS } from "src/utils/constants";
import { ReviewerConfig } from "./types";
import { userService } from "src/services";

/**
 * Checks if button should visible based on provided permissions
 * @param reviewer
 * @param activeServiceLine
 * @param permissionKeys
 * @param roles
 */
export const shouldButtonVisible = (
  reviewer: ReviewerConfig,
  activeServiceLine: ServiceLineObject,
  permissionKeys: Array<string>,
  roles?: Array<string>,
  canvasInformation?: CanvasInformationObject
): boolean => {
  if (reviewer && !permissionKeys.includes(reviewer.permission)) return false;
  let shouldVisible = true;
  if (roles && roles.length) {
    const { processingState, procedure } = activeServiceLine;
    const overjetReviewResult = get(
      activeServiceLine,
      "review.ai.decision.result"
    );

    const pendCode = get(activeServiceLine, "review.ai.decision.code");
    if (processingState && procedure) {
      if (reviewer.visibility) {
        const loggedInUserRule = reviewer.visibility.find(
          (item) => item.role === roles[0]
        );
        if (loggedInUserRule) {
          if (!loggedInUserRule.claimStatus.includes(processingState)) {
            shouldVisible = false;
          }

          // To keep hidden button if Role has limited access to see button based on overjet review result
          // Only allowed overjet review result that are described in config would be able to display button
          if (
            loggedInUserRule.allowedOJRR &&
            !loggedInUserRule.allowedOJRR.includes(overjetReviewResult || "")
          ) {
            shouldVisible = false;
          }

          // Always show button when claimStatus and overjet review result conditions met
          if (loggedInUserRule?.shouldAlwaysVisible) {
            loggedInUserRule.shouldAlwaysVisible.forEach((item) => {
              if (
                item.claimStatus === processingState &&
                item.ojrr.includes(overjetReviewResult)
              ) {
                shouldVisible = true;
              }
            });
          }

          if (loggedInUserRule?.shouldHideOnOJRR) {
            loggedInUserRule.shouldHideOnOJRR.forEach((item: any) => {
              if (item === pendCode) {
                shouldVisible = false;
              }
            });
          }

          if (loggedInUserRule?.shouldShowOnOJRR && shouldVisible) {
            shouldVisible = !!loggedInUserRule.shouldShowOnOJRR.find(
              (item) => item === pendCode
            );
          }

          // Below condition will hide button if CDT code doesn't match with what described in config
          if (
            reviewer.visibilityOnCDTcode &&
            !reviewer.visibilityOnCDTcode.includes(procedure)
          ) {
            shouldVisible = false;
          }

          if (shouldVisible && reviewer.hideIfAnyClaimAttributeIsTrue) {
            reviewer.hideIfAnyClaimAttributeIsTrue.forEach(
              (claimAttribute: string) => {
                const attributeValue: boolean = get(
                  canvasInformation?.currentClaimInformation,
                  claimAttribute
                );
                if (attributeValue === true) {
                  shouldVisible = false;
                }
              }
            );
          }
        }
      }
    }
  }

  return shouldVisible;
};

/**
 * Checks if button should visible when claim state matches with user assigned states
 * @param reviewer
 * @param prediction
 * @param stateLicenses
 * @param roles
 * @returns Boolean
 */

export const stateLicenseCheck = (
  reviewer: ReviewerConfig,
  activeServiceLine: ServiceLineObject,
  stateLicenses: GenericObject,
  roles: Array<string>,
  canvasInformation?: CanvasInformationObject
): boolean => {
  if (reviewer.name === "comment") return false;
  let hideButton = false;
  const { providerInfo = {}, currentClaimInformation } =
    canvasInformation || {};
  const { specialtyName = "" } = currentClaimInformation || {};
  const { providerState = "" } = providerInfo || {};
  const { ingressType = "", processingState = "" } = activeServiceLine || {};
  const { licenseSpeciality = "" } =
    userService.getUserProfile().userInformation || {};
  let specialtyRulesOnProcessingState = null;
  if (reviewer?.visibilityOnSpecialty) {
    specialtyRulesOnProcessingState =
      reviewer.visibilityOnSpecialty[processingState];
  }

  if (specialtyRulesOnProcessingState) {
    if (!licenseSpeciality) {
      hideButton = true;
      return hideButton;
    }
    const userSpecialty: Array<string> =
      specialtyRulesOnProcessingState[licenseSpeciality];
    if (userSpecialty && userSpecialty.includes(specialtyName)) {
      // return false, So that stateLicensureCheck should not return null but HTML element
      hideButton = false;
      return hideButton;
    } else {
      hideButton = true;
      return hideButton;
    }
  } else {
    let stateLicenseRequired = false;
    if (reviewer.visibility) {
      const loggedInUserRule: any = reviewer.visibility.find(
        (item: any) => item.role === roles[0]
      );
      if (providerState) {
        if (
          loggedInUserRule?.licenseRequiredState &&
          loggedInUserRule.licenseRequiredState[providerState]
        ) {
          let licenseRequired =
            loggedInUserRule?.licenseRequiredState[providerState];
          // Some states based-on claim type have no resident licensure restriction,
          // So false can be a safe return to display button
          if (!licenseRequired.claimIngressType.includes(ingressType)) {
            stateLicenseRequired = false;
            return stateLicenseRequired;
          }
          const listOfLicenses = Object.keys(stateLicenses);
          if (!listOfLicenses.includes(providerState)) {
            stateLicenseRequired = true;
          }
        }
      }
    }
    hideButton = stateLicenseRequired;
  }
  return hideButton;
};

/**
 * Checks if claim has permission set to readonly
 * @param reviewer
 * @param prediction
 * @returns
 */

export const isClaimReadable = (
  reviewer: ReviewerConfig,
  activeServiceLine: ServiceLineObject
): boolean | undefined => {
  if (reviewer.name === "comment") return false;
  return activeServiceLine.isActionButtonReadOnly;
};

export const getDropDownValues = (
  reviewer: ReviewerConfig,
  activeServiceLine: ServiceLineObject
) => {
  const { procedure, ingressType } = activeServiceLine;
  let dropDownReasons: any = [];

  if (reviewer.options) {
    reviewer.options.forEach((reviewObj) => {
      let reasonSelected = false;
      let showReason = true;
      if (reviewObj.hideOnCurrentClaimType?.length) {
        showReason = reviewObj.hideOnCurrentClaimType.some(
          (claimType: string) => claimType !== ingressType
        );
      }
      if (showReason && reviewObj.hideOnReviewResults?.length) {
        showReason = !reviewObj.hideOnReviewResults.some(
          (reviewResult: string) =>
            reviewResult === activeServiceLine?.review?.decision?.code
        );
      }
      if (showReason && reviewObj.hideOnSpecialtyState) {
        showReason =
          activeServiceLine?.originalClaimInformation?.specialtyState === true;
      }

      if (reviewObj.universal) {
        reasonSelected = true;
      } else if (reviewObj.listOfProcedures?.includes(procedure || "")) {
        reasonSelected = true;
      } else {
        if (procedure) {
          let procedureValue = procedure?.slice(procedure?.indexOf("D") + 1); // Remove D before range compare
          reviewObj.rangeOfProcedures?.forEach((procedureRange: any) => {
            if (
              procedureValue >= procedureRange.min &&
              procedureValue <= procedureRange.max
            ) {
              reasonSelected = true;
            }
          });
        }
      }
      let newLabel = "";
      if (reviewObj.conditionalText) {
        reviewObj.conditionalText.forEach((condition: any) => {
          if (
            condition?.claimType?.find((type: string) => type === ingressType)
          ) {
            newLabel = condition?.newText;
          }
        });
      }
      if (reasonSelected && showReason) {
        dropDownReasons.push({
          ...reviewObj,
          text: newLabel ? newLabel : reviewObj.text,
        });
      }
    });
    return dropDownReasons;
  }
};

export const isEditable = (
  reviewer: ReviewerConfig,
  activeServiceLine: ServiceLineObject
) => {
  const reviewResult =
    get(activeServiceLine, "review.ai.decision.result") || "";
  const pendCode = get(activeServiceLine, "review.ai.decision.code") || "";
  const { visibilityOnOJRR } = reviewer;
  if (
    visibilityOnOJRR &&
    visibilityOnOJRR[pendCode] === CLAIM_ACTION_STATUS.DISABLED
  ) {
    return false;
  }

  if (
    visibilityOnOJRR &&
    visibilityOnOJRR[reviewResult] === CLAIM_ACTION_STATUS.DISABLED
  ) {
    return false;
  }

  return true;
};
