import { KeyTitleObject, QuadrantRangeI } from "src/defs";
export const QUADRANT_RANGE: QuadrantRangeI = {
  UR: [1, 8],
  UL: [9, 16],
  LL: [17, 24],
  LR: [25, 32],
  NA: [0, 0],
  UA: [0, 0],
  LA: [0, 0],
  ARCH: [1, 32],
};
export const DATE_FORMAT = {
  QUERY_DATE_FORMAT: "YYYY-MM-DD",
};

export const CLAIM_TYPES: Array<KeyTitleObject> = [
  {
    key: "INITIAL",
    title: "Initial",
  },
  {
    key: "RFI",
    title: "RFI Return",
  },
  {
    key: "APPEAL",
    title: "Appeal",
  },
];

export const SYSTEM_TYPES: Array<KeyTitleObject> = [
  {
    key: "CBIS",
    title: "CBIS",
  },
  {
    key: "MTV",
    title: "MTV",
  },
];

export const SOURCE_TYPES: Array<KeyTitleObject> = [
  {
    key: "DDS",
    title: "DDS",
  },
  {
    key: "WDS",
    title: "WDS",
  },
];

export const STATES: Array<KeyTitleObject> = [
  {
    key: "AL",
    title: "Alamaba",
  },
  {
    key: "AK",
    title: "Alaska",
  },
  {
    key: "AZ",
    title: "Arizona",
  },
  {
    key: "AR",
    title: "Arkansas",
  },
  {
    key: "CA",
    title: "California",
  },
  {
    key: "CO",
    title: "Colorado",
  },
  {
    key: "DC",
    title: "District of Columbia",
  },
  {
    key: "CT",
    title: "Connecticut",
  },
  {
    key: "DE",
    title: "Delaware",
  },
  {
    key: "FL",
    title: "Florida",
  },
  {
    key: "GA",
    title: "Georgia",
  },
  {
    key: "GU",
    title: "Guam",
  },
  {
    key: "HI",
    title: "Hawaii",
  },
  {
    key: "ID",
    title: "Idaho",
  },
  {
    key: "IL",
    title: "Illinois",
  },
  {
    key: "IN",
    title: "Indiana",
  },
  {
    key: "IA",
    title: "Iowa",
  },
  {
    key: "KS",
    title: "Kansas",
  },
  {
    key: "KY",
    title: "Kentucky",
  },
  {
    key: "LA",
    title: "Louisiana",
  },
  {
    key: "ME",
    title: "Maine",
  },
  {
    key: "MD",
    title: "Maryland",
  },
  {
    key: "MA",
    title: "Massachusetts",
  },
  {
    key: "MI",
    title: "Michigan",
  },
  {
    key: "MN",
    title: "Minnesota",
  },
  {
    key: "MS",
    title: "Mississippi",
  },
  {
    key: "MO",
    title: "Missouri",
  },
  {
    key: "MT",
    title: "Montana",
  },
  {
    key: "NE",
    title: "Nebraska",
  },
  {
    key: "NV",
    title: "Nevada",
  },
  {
    key: "NH",
    title: "New Hampshire",
  },
  {
    key: "NJ",
    title: "New Jersey",
  },
  {
    key: "NM",
    title: "New Mexico",
  },
  {
    key: "NY",
    title: "New York",
  },
  {
    key: "NC",
    title: "North Carolina",
  },
  {
    key: "ND",
    title: "North Dakota",
  },
  {
    key: "OH",
    title: "Ohio",
  },
  {
    key: "OK",
    title: "Oklahoma",
  },
  {
    key: "OR",
    title: "Oregon",
  },
  {
    key: "PA",
    title: "Pennsylvania",
  },
  {
    key: "RI",
    title: "Rhode Island",
  },
  {
    key: "SC",
    title: "South Carolina",
  },
  {
    key: "SD",
    title: "South Dakota",
  },
  {
    key: "TN",
    title: "Tennessee",
  },
  {
    key: "TX",
    title: "Texas",
  },
  {
    key: "UT",
    title: "Utah",
  },
  {
    key: "VT",
    title: "Vermont",
  },
  {
    key: "VA",
    title: "Virginia",
  },
  {
    key: "WA",
    title: "Washington",
  },
  {
    key: "WV",
    title: "West Virginia",
  },
  {
    key: "WI",
    title: "Wisconsin",
  },
  {
    key: "WY",
    title: "Wyoming",
  },
  {
    key: "OT",
    title: "Others",
  },
];

export const DATE_RANGE_FORMULA = {
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  QUATERLY: "QUATERLY",
};

export const CLIENT_IDS = {
  elevance: "elevancehealth",
};
