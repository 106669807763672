import { cloneDeep, flatMap, isEmpty, isObject } from "lodash";
import { v4 } from "uuid";
import { fabric } from "fabric";
import {
  faCircleXmark,
  faCircleCheck,
  faClock,
  faCircleHalfStroke,
  faTooth,
  faCircleQuestion,
  faHourglassHalf,
} from "@fortawesome/pro-regular-svg-icons";
import { GenericObject } from "src/defs";
import { SRP_AND_PERIO_CRITERIA_PROCEDURES } from "src/constants/constant";
import { cacheService, userService } from "src/services";
import {
  SOURCE_IMAGE_EMPTY_PREDICTION,
  FINDING_LABELS,
  HEADING_LABELS,
} from "src/components/new-design/canvas/canvas-constant";
import { LABEL_COLORS } from "src/components/canvas/constant";
import {
  CalculusDiseaseObject,
  BoneApexLevelProps,
  CanvasInformationObject,
  BridgeI,
  FindingI,
  ImagePredictionI,
  ToothNumberObject,
  ServiceLineObject,
} from "src/context/canvas/types";
import { PredictionObject } from "src/components/new-design/canvas/types";
import {
  CanvasDimensionProps,
  PolygonI,
  CanvasProperties,
  Geometry,
} from "./types";
import {
  quadrantBasedProcedure,
  toothBasedProcedure,
  noToohNoQuadrantProcedure,
  IMPLANTS_PROCEDURES,
} from "src/constants/constant";
import FabricTypes from "fabric/fabric-impl";
import {
  LOADING,
  NO_PREDICTION_POSSIBLE,
  SOURCE_IMAGES,
} from "src/constants/info-constant";
import { ReviewerConfig } from "src/components/new-design/canvas/reviewer-actions/types";
import {
  isClaimReadable,
  shouldButtonVisible,
  stateLicenseCheck,
} from "src/components/new-design/canvas/reviewer-actions/reviewer-helper";
import { extractOnlyImageName } from "src/components/shared/helper";
import { extractAnnotationDisplayRules } from "src/components/new-design/fmx/fmx-sidebar/helper";

export const wisdomTeeth = [1, 16, 17, 32];

export const annotationDisplaRules = [
  {
    listOfProcedures: ["D2335"],
    allowFindings: [
      "bone_level",
      "apex_level",
      "Calculus",
      "Filling/Inlay",
      "Missing",
      "RootCanal",
      "Decay",
      "Attrition/Erosion",
      "Pontic",
      "Retainer",
      "Crown",
    ],
  },
  {
    listOfProcedures: ["D2332", "D2335", "D2392", "D2391", "D2331"],
    allowFindings: ["bone_level", "apex_level", "Calculus", "Decay"],
  },
  {
    listOfProcedures: ["D3310", "D3320", "D3330"],
    allowFindings: ["bone_level", "apex_level", "Calculus", "Decay", "Parl"],
  },
  {
    listOfProcedures: ["D4341", "D4342", "D4381"],
    allowFindings: ["bone_level", "apex_level", "Calculus"],
  },
  {
    listOfProcedures: [
      "D6010",
      "D6056",
      "D6057",
      "D6058",
      "D6059",
      "D6060",
      "D6061",
      "D6062",
      "D6063",
      "D6064",
      "D6065",
      "D6066",
      "D6068",
      "D6094",
      "D6190",
      "D6104",
    ],
    allowFindings: ["bone_level", "apex_level", "Calculus"],
  },
  {
    listOfProcedures: [
      "D4265",
      "D4210",
      "D4211",
      "D4240",
      "D4241",
      "D4260",
      "D4261",
      "D4263",
      "D4264",
      "D4265",
      "D4266",
      "D4267",
      "D4270",
      "D4273",
      "D4274",
      "D4275",
      "D4276",
      "D4277",
      "D4278",
      "D4283",
      "D4285",
    ],
    allowFindings: ["bone_level", "apex_level"],
  },
  {
    rangeOfProcedures: [
      {
        min: 2500,
        max: 2799,
      },
    ],
    allowFindings: [
      "Filling/Inlay",
      "Missing",
      "RootCanal",
      "Decay",
      "Attrition/Erosion",
      "Pontic",
      "Retainer",
      "Crown",
      "Parl",
    ],
  },
  {
    listOfProcedures: ["D6100", "D6094"],
    allowFindings: ["bone_level", "apex_level", "Calculus"],
  },
  {
    listOfProcedures: ["D7210", "D7250", "D7140", "D7111"],
    allowFindings: [
      "bone_level",
      "apex_level",
      "Crown",
      "Decay",
      "RootCanal",
      "Filling/Inlay",
    ],
  },
];

export function isCanvasScrollable(
  imageType: string,
  sourceImageType: string | undefined
) {
  if (["bw", "pa", "pan", "intra"].includes(imageType)) {
    return false;
  } else if (imageType === "source") {
    // Source images with more than 1 cropped wouldn't get sourceImageType field.
    if (!sourceImageType) return true;
    else if (["bw", "pa", "pan", "intra"].includes(sourceImageType))
      return false;
  }
  return true;
}

/**
 * Get width and height of canvas element
 * @param props The props from the component
 * @returns
 */
export function getCanvasDimension(props: Partial<CanvasDimensionProps>) {
  const dimension: {
    width: number;
    height: number;
  } = {
    width: 0,
    height: 0,
  };
  const canvasContainer = document
    .getElementsByClassName(props.className || "")
    .item(0);
  if (canvasContainer) {
    dimension.width = (canvasContainer.clientWidth / 12) * 10;
    dimension.height = (canvasContainer.clientHeight / 10) * 7;
  }
  return dimension;
}

/**
 *
 * @param fabricCanvas The canvas object
 * @param radius The radius of circle object
 */
export function setEdgeCricleRadius(
  fabricCanvas: FabricTypes.Canvas,
  radius: number
) {
  const { criteriaMet } = userService.getCanvasConfig();
  if (fabricCanvas) {
    fabricCanvas.getObjects().forEach((obj: any) => {
      if (isObject(obj.data) && "isCircle" in obj.data) {
        const { isCircle }: any = obj.data;
        if (isCircle) {
          let radiusParam = radius;
          if (obj?.data?.imageType === "pan") radiusParam = 0.5;
          obj.setRadius(radiusParam * 2);
        }
      }
    });
    if (criteriaMet?.showApexPoint === false) {
      fabricCanvas.getObjects().forEach((obj: any) => {
        if (obj.name === "apex_level") {
          obj.radius = 0;
        }
      });
      fabricCanvas.renderAll();
    }
    fabricCanvas.renderAll();
  }
}

/**
 * Get circle radius from the zoom level
 *
 * @param zoomLevel The zoom level
 * @param props The props from the component
 * @returns Number
 */
export function getCircleRadius(
  zoomLevel: number,
  props: Partial<CanvasDimensionProps>
): number {
  const { height, width } = getCanvasDimension(props);
  const measurement = width < height ? width : height;
  return measurement / (zoomLevel * 300);
}

/**
 * Get canvas area zoom factor based on image width and height
 *
 * @param {FabricTypes.Image} image
 * @param {Partial<CanvasDimensionProps>} props
 *
 * @returns Object contains image size and zoom factor
 */

export function getCanvasZoom(
  image: FabricTypes.Image,
  props: Partial<CanvasDimensionProps>
) {
  const { width, height } = getCanvasDimension(props);
  let zoom = 1;
  if (image) {
    const widthFactor = width / image.getScaledWidth();
    const heightFactor = height / image.getScaledHeight();
    zoom = widthFactor < heightFactor ? widthFactor : heightFactor;
  }
  return zoom;
}

/**
 * Display scrollable images the canvas background image
 * @param {FabricTypes.Canvas} fabricCanvas - The fabric object
 * @param {Number} width - Image width
 * @param {Number} height - Image height
 */
export function displayScrollableImages(
  fabricCanvas: FabricTypes.Canvas,
  width: number,
  height: number
) {
  const container: any = document.querySelectorAll(".canvas-container") || [];
  let widthFactor = 0;
  if (container && container[1]) {
    container[1].style.overflowY = "scroll";
    container[1].classList.add("canvas-container-non-xray");
    // Subtract 10px to separate(padding) scrollbar from image.
    widthFactor = (container[1].clientWidth - 10) / width;
    fabricCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    fabricCanvas.setZoom(widthFactor);
    fabricCanvas.setDimensions({
      width: width * widthFactor,
      height: height * widthFactor,
    });
  }
}

/**
 * Resize the canvas background image
 * @param {FabricTypes.Canvas} fabricCanvas - The fabric object
 * @param {CanvasDimensionProps} props - The props object
 * @param {(properties: any) => void} setCanvasProperties - Set the canvas properties
 * @param {(image: FabricTypes.Image) => void} setPolygonImage - Set polygon image
 */
export function resizeCanvasBackgroundImage(
  fabricCanvas: FabricTypes.Canvas,
  props: Partial<CanvasDimensionProps>,
  setCanvasProperties?: (properties: any) => void,
  setPolygonImage?: (image: FabricTypes.Image) => void
) {
  const { width, height } = getCanvasDimension(props);
  fabricCanvas.setDimensions({ height, width });
  const image = fabricCanvas.backgroundImage;
  let zoom = 1;
  let left = 0;
  if (image && typeof image === "object") {
    zoom = getCanvasZoom(image, props);
    const imageWidth = image.getScaledWidth();
    left = (fabricCanvas.getWidth() - imageWidth * zoom) / 2;
    fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, left, 0]);
    fabricCanvas.setBackgroundImage(
      image,
      fabricCanvas.renderAll.bind(fabricCanvas),
      {
        top: 0,
        left: 0,
      }
    );
    if (setCanvasProperties) {
      const properties: CanvasProperties = {
        strokeWidth: getCircleRadius(zoom, props),
        zoomLevel: zoom,
      };
      setCanvasProperties(properties);
      const radius = getCircleRadius(zoom, props);
      setEdgeCricleRadius(fabricCanvas, radius);
    }

    if (setPolygonImage && image) {
      setPolygonImage(image);
    }
  }
}

/**
 * Apply filter to canvas image
 */
function applyFiltersToBGImage(
  image: FabricTypes.Image,
  properties?: CanvasProperties
) {
  if (isEmpty(properties)) {
    return;
  }

  if (properties?.contrast !== undefined) {
    image.filters?.push(
      new fabric.Image.filters.Contrast({ contrast: properties.contrast / 100 })
    );
  }

  if (properties?.brightness !== undefined) {
    image.filters?.push(
      new fabric.Image.filters.Brightness({
        brightness: properties.brightness / 100,
      })
    );
  }

  image.applyFilters();
}

/**
 * Apply zoom to canvas image
 */
function applyZoomToBGImage(
  canvas: FabricTypes.Canvas,
  properties?: CanvasProperties
) {
  if (isEmpty(properties)) {
    return;
  }

  if (properties?.zoom) {
    canvas.setZoom(properties.zoom);
    canvas.renderAll();
  }
}

/**
 * Apply rotate to canvas image
 */
function applyRotateToBGImage(
  canvas: FabricTypes.Canvas,
  properties?: CanvasProperties
) {
  if (isEmpty(properties)) {
    return;
  }

  if (properties?.rotate && canvas.backgroundImage) {
    const bgImage = canvas.backgroundImage as FabricTypes.Image;
    bgImage.rotate(properties.rotate);
    const canvasCenter = new fabric.Point(
      canvas.getWidth() / 2,
      canvas.getHeight() / 2
    ); // center of canvas
    const radians = fabric.util.degreesToRadians(properties.rotate);
    canvas.getObjects().forEach((obj) => {
      const objectOrigin = new fabric.Point(obj.left || 0, obj.top || 0);
      const new_loc = fabric.util.rotatePoint(
        objectOrigin,
        canvasCenter,
        radians
      );
      obj.top = new_loc.y;
      obj.left = new_loc.x;
      obj.angle = properties.rotate; //rotate each object by the same angle
      obj.setCoords();
    });
  }
}

/**
 * Set the background image on canvas area
 * @param {FabricTypes.Canvas} fabricCanvas - The canvas object
 * @param {FabricTypes.Image} image - The image object
 * @param {CanvasDimensionProps} props - The props object
 * @param {(image: FabricTypes.Image) => void} setPolygonsImage - The action to update Polygons Image
 * @param {(properties: any) => void} setCanvasProperties - The action to update Canvas Properties
 */
export function setCanvasBackgroundImage(
  fabricCanvas: FabricTypes.Canvas,
  image: FabricTypes.Image,
  props: Partial<CanvasDimensionProps>,
  setPolygonsImage?: (image: FabricTypes.Image) => void,
  setCanvasProperties?: (properties: any) => void
) {
  const zoom = getCanvasZoom(image, props);
  const left = (fabricCanvas.getWidth() - image.getScaledWidth() * zoom) / 2;
  fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, left, 0]);
  fabricCanvas.setBackgroundImage(
    image,
    fabricCanvas.renderAll.bind(fabricCanvas),
    {
      top: 0,
      left: 0,
    }
  );
  applyFiltersToBGImage(image, props.canvasProperties);
  applyZoomToBGImage(fabricCanvas, props.canvasProperties);
  applyRotateToBGImage(fabricCanvas, props.canvasProperties);
  const setCanvasPropertyValues = props.setCanvasPropertiesAction
    ? props.setCanvasPropertiesAction
    : setCanvasProperties;
  if (setCanvasPropertyValues) {
    const properties: CanvasProperties = {
      ...(props.canvasProperties ?? {}),
      strokeWidth: getCircleRadius(zoom, props),
      zoom:
        props.canvasProperties?.zoomed || zoom || props.canvasProperties?.zoom,
      zoomLevel: zoom,
    };
    setCanvasPropertyValues(properties);
    const radius = getCircleRadius(zoom, props);
    setEdgeCricleRadius(fabricCanvas, radius);
  }
}

/**
 * Append source images to filter array
 * @param sourceImages {GenericObject} - The source images object
 * @param filter {Array<PredictionObject>} - The filter array
 */

export function mergeSourceImages(
  sourceImages: GenericObject,
  filter: Array<PredictionObject>
) {
  if (sourceImages) {
    Object.values(sourceImages).forEach((source, i) => {
      const emptyPrediction = cloneDeep(SOURCE_IMAGE_EMPTY_PREDICTION);
      if (typeof source === "object") {
        emptyPrediction.imageUri = source.sourceImage;
        emptyPrediction.sourceImages = source.sourceImage;
        emptyPrediction.imageReceiptDate = source.imageReceiptDate;
        emptyPrediction.isNewTag = source.isNewTag;
        emptyPrediction.isHistoricImage = source.isHistoricImage;
        emptyPrediction.uniqueId = v4();
        emptyPrediction.height = source.height;
        emptyPrediction.width = source.width;
        emptyPrediction.sourceImageType = source.sourceImageType;
      } else {
        emptyPrediction.imageUri = source;
        emptyPrediction.sourceImages = source;
        emptyPrediction.height = source.height;
        emptyPrediction.width = source.width;
        emptyPrediction.sourceImageType = source.sourceImageType;
      }
      filter.push(emptyPrediction);
    });
  }
}

/**
 * Get the source images from the prediction
 * @param sourceImages
 * @param filter The filter array to add source images into
 *
 * @returns Affects the provided filter array
 */
export function getSourceImages(
  predictionObjs: Array<PredictionObject> | string,
  filter: Array<PredictionObject>
) {
  const clonedFilter = cloneDeep(filter);
  if (predictionObjs && Array.isArray(predictionObjs)) {
    predictionObjs.forEach((prediction) => {
      const match = clonedFilter.find(
        (f) => f.uniqueId === prediction.uniqueId
      );
      if (!match) {
        const emptyPrediction = cloneDeep(SOURCE_IMAGE_EMPTY_PREDICTION);
        if (typeof prediction === "object") {
          emptyPrediction.imageUri = prediction.imageUri;
          emptyPrediction.prediction = prediction.prediction;
          emptyPrediction.sourceImages = prediction.sourceImages;
          emptyPrediction.imageReceiptDate = prediction.imageReceiptDate;
          emptyPrediction.isNewTag = prediction.isNewTag;
          emptyPrediction.uniqueId = prediction.uniqueId;
          emptyPrediction.height =
            prediction.prediction?.classifications?.im_height;
          emptyPrediction.width =
            prediction.prediction?.classifications?.im_width;
        } else {
          emptyPrediction.imageUri = prediction;
          emptyPrediction.sourceImages = prediction;
        }
        filter.push(emptyPrediction);
      }
    });
  }
}

/**
 * Get polygon base object for tooth finding
 *
 * @param findings The findings object which contain claim findings
 * @param key The finding iteration key
 * @param tooth The tooth object from the finding
 * @param geometry The geometry object from the finding
 *
 * @returns The polygon object
 */
export function transformToothPolygonObject(
  findings: FindingI,
  key: string,
  tooth?: any,
  geometry?: any
): PolygonI {
  let geometryVal = undefined;
  if (geometry) {
    geometryVal = [geometry];
  }
  return {
    uuid: v4(),
    add_finding: false,
    color: findings[key].color,
    delete_finding: false,
    deleted: false,
    geometry: geometryVal,
    geometry_labels: findings[key].geometry_labels,
    hidden: false,
    id: findings[key].id,
    key: getFindingLabel(key),
    metaclass_type: getFindingLabel(findings[key].metaclass_type),
    parentObjectName: findings[key].parentObjectName,
    polygonType: findings[key].geometry_type,
    subType: getFindingLabel(findings[key].metaclass_sub_type),
    toothNumber: tooth ? [parseInt(tooth) + ""] : "",
    type: getFindingLabel(findings[key].class_type),
    measurement: findings[key].measurement,
  };
}

/**
 * Get polygon base object for apex level finding
 *
 * @param apexLevelProps The apex level props
 * @param index Iteration index key
 * @param parentKey Parent iteration index key
 * @param pointPair Apex props points pair
 * @param parentObjectName Apex props parent object name
 * @param toothNumber The tooth number of apex level
 * @param label The label for apex level
 * @param quadrant Current prediction quadrant value
 * @returns Polygon object
 */
export function transformApexPolygonObject(
  apexLevelProps: BoneApexLevelProps,
  index: number,
  parentKey: string,
  pointPair: any,
  parentObjectName: string,
  toothNumber: string,
  label: string | undefined,
  quadrant: string | undefined
): PolygonI {
  let geometry_labels = undefined;
  if (label) {
    geometry_labels = [`${Math.round(parseFloat(label) * 100)}%`];
  }
  return {
    uuid: v4(),
    add_finding: false,
    color: LABEL_COLORS.BoneRatioCriteriaNotMet,
    delete_finding: false,
    deleted: false,
    direction: apexLevelProps.direction[index],
    geometry: [pointPair],
    geometry_labels,
    hidden: false,
    id: index.toString(),
    isPa: apexLevelProps.isPa,
    metaclass_type: { Findings: "Perio" },
    parentKey,
    parentObjectName,
    polygonType: "line",
    strokeWidth: 0,
    type: "Findings",
    toothNumber: [toothNumber],
    quadrant,
    imageType: apexLevelProps.imageType,
  };
}

/**
 * Get polygon base object for bone level finding
 *
 * @param boneLevelProps
 * @param index
 * @param parentKey
 * @param pointPair
 * @param parentObjectName
 * @param toothNumber
 * @param label
 * @param quadrant
 * @returns
 */
export function transformBonePolygonObject(
  boneLevelProps: BoneApexLevelProps,
  index: number,
  parentKey: string,
  pointPair: any,
  parentObjectName: string,
  toothNumber: string,
  label: string | undefined,
  quadrant: string | undefined
): PolygonI {
  let geometry_labels = undefined;
  if (label) {
    geometry_labels = [`${parseFloat(label).toFixed(1)}`];
  }
  return {
    uuid: v4(),
    add_finding: false,
    color: LABEL_COLORS.BoneLevelCriteriaNotMet,
    delete_finding: false,
    deleted: false,
    direction: boneLevelProps.direction[index],
    geometry: [pointPair],
    geometry_labels,
    geometry_new_labels: [],
    hidden: false,
    hideLabel: boneLevelProps.hideLabel,
    id: index.toString(),
    metaclass_type: { Findings: "Perio" },
    hideLine: boneLevelProps.hideLine,
    parentKey,
    parentObjectName,
    polygonType: "line",
    type: "Findings",
    toothNumber: [toothNumber],
    quadrant,
    imageType: boneLevelProps.imageType,
  };
}

/**
 * Get heading for the finding
 *
 * @param procedureCode The procedure code
 * @param headingNumber Heading number
 * @returns The heading
 */

export function getTypeHeading(procedureCode: string, headingNumber: number) {
  // Heading number could be 1 or 2
  // 1 For criteria met and 2 for criteria not met
  if (SRP_AND_PERIO_CRITERIA_PROCEDURES.includes(procedureCode)) {
    return headingNumber === 1
      ? HEADING_LABELS.Criteria_Met
      : HEADING_LABELS.Criteria_Not_Met;
  }
  return HEADING_LABELS.Findings;
}

/**
 * Filter the pontic teeth in order to hide Bone level and Bone ratio
 * @params toothObject The prediction object which contains bridge information
 */
export function filterBoneLevelFromPonticTeeth(toothObject: any) {
  const { bridge } = toothObject;
  if (bridge) {
    const { Bridge } = bridge;
    const keys = Object.keys(toothObject);
    const bridgeTeeth = flatMap(Bridge.bridge_subcategory, (tooths: any) => {
      return tooths.map((tooth: any) => tooth);
    });
    keys.forEach((tooth) => {
      const toothLabel = bridgeTeeth.find((toothObj) => {
        const key = Object.keys(toothObj).pop();
        const value = Object.values(toothObj).pop();
        if (Number(key) === Number(tooth) && value === "Pontic") {
          return true;
        }
        return false;
      });
      if (toothLabel) {
        delete toothObject[tooth];
      }
    });
  }
}

/**
 * Filter tooth by quadrant range
 * @param toothObject The teeth object which contains finding information
 * @param quadrantRange The quadrant of the claim
 * @param skip To skip tooth filtering completely
 *
 * @returns Affects the teeth object
 */

export function filterTeeth(
  toothObject: {
    [x: string]: any;
  },
  quadrantRange: any,
  skip: boolean = false
) {
  if (skip) return;
  const keys = Object.keys(toothObject);
  for (let i = keys.length - 1; i >= 0; i--) {
    const tooth = Number(keys[i]);
    if (quadrantRange) {
      if (!(tooth >= quadrantRange[0] && tooth <= quadrantRange[1])) {
        delete toothObject[tooth];
      }
    }
  }
}

/**
 * Filter calculus teeth and geometry
 * @param calculusObject The teeth object which contains calculus information
 * @param quadrantRange The quadrant of the claim
 *
 * @returns Affects the teeth object
 */

export function filterCalculusTeethAndGeometry(
  calculusObject: CalculusDiseaseObject,
  quadrantRange: Array<any>
) {
  if (calculusObject && calculusObject.tooth_number) {
    const { tooth_number } = calculusObject;
    for (let i = tooth_number.length - 1; i >= 0; i--) {
      const toothNumber = Number(tooth_number[i][0]);
      if (
        !(toothNumber >= quadrantRange[0] && toothNumber <= quadrantRange[1])
      ) {
        delete calculusObject.geometry[i];
        delete calculusObject.tooth_number[i];
        if (calculusObject.calculus_is_on_enamel) {
          delete calculusObject.calculus_is_on_enamel[i];
        }
      }
    }
  }
}

/**
 * Check if object has keys
 * @param obj The object
 * @returns Boolean
 */

export function objectHasValue(obj: any) {
  return obj && Object.keys(obj).length;
}

/**
 * Get label from underscored key
 * @param key The key to modify
 * @returns The new label
 */
export function getFindingLabel(key: string): string {
  if (typeof key === "string" && key.split("_")[0]) {
    return key.split("_")[0];
  }
  return FINDING_LABELS[key] || key;
}

/**
 * Checks if provided procedure code matches with
 * quadrant based procedure codes
 *
 * @param pCode The procedure code to check
 * @returns Boolean
 */
export function isQuadrantProcedure(pCode: string): boolean {
  return quadrantBasedProcedure.includes(pCode);
}

/**
 * Checks if provided procedure code matches with
 * pano based procedure codes
 *
 * @param pCode The procedure code to check
 * @returns Boolean
 */
export function isPanoProcedure(pCode: string): boolean {
  return pCode.startsWith("D7");
}

/**
 * Checks if provided procedure code matches with
 * tooth based procedure codes
 *
 * @param pCode The procedure code to check
 * @returns Boolean
 */
export function isToothProcedure(pCode: string): boolean {
  return pCode.startsWith("D2") ||
    pCode.startsWith("2") ||
    pCode.startsWith("D6") ||
    pCode.startsWith("D3") ||
    IMPLANTS_PROCEDURES.includes(pCode)
    ? true
    : toothBasedProcedure.includes(pCode);
}

/**
 * Check if procedure code matches with all provided procedure codes
 * @param pCode The procedure string to match
 * @param codes The codes collection to match against
 * @returns Boolean
 */

export function procedureContainCodes(
  pCode: string,
  codes: Array<string>
): boolean {
  let match = true;
  codes.forEach((code) => {
    if (pCode.indexOf(code) === 0) {
      match = true;
    } else {
      match = false;
    }
  });
  return match;
}

/**
 * Checks if provided procedure code matches with
 * no tooth and no quadrant based procedure codes
 *
 * @param pCode The procedure code to check
 * @returns Boolean
 */
export function noToothNoQuadrantProcedure(pCode: string): boolean {
  return noToohNoQuadrantProcedure.includes(pCode);
}

/**
 * This function will take Image width as input and Distance in pixel for Distal and Meisal
 * Used to draw label on teeth (insteat of sides).
 * This function will help to adjust position of label that draw on LR and UR Quadrant
 * @param {number} imageWidth
 */
export function zoomToPixelRightQuadrant(imageWidth: number) {
  switch (true) {
    case imageWidth < 100:
      return { Single: 5, Double: 6 };
    case imageWidth >= 100 && imageWidth < 125:
      return { Single: 8, Double: 10 };
    case imageWidth >= 125 && imageWidth < 150:
      return { Single: 8, Double: 10 };
    case imageWidth >= 150 && imageWidth < 175:
      return { Single: 8, Double: 10 };
    case imageWidth >= 175 && imageWidth < 200:
      return { Single: 8, Double: 10 };
    case imageWidth >= 200 && imageWidth < 225:
      return { Single: 8, Double: 8 };
    case imageWidth >= 225 && imageWidth < 250:
      return { Single: 8, Double: 10 };
    case imageWidth >= 250 && imageWidth < 275:
      return { Single: 8, Double: 16 };
    case imageWidth >= 275 && imageWidth < 300:
      return { Single: 8, Double: 16 };
    case imageWidth >= 300 && imageWidth < 325:
      return { Single: 9, Double: 17 };
    case imageWidth >= 325 && imageWidth < 350:
      return { Single: 9, Double: 17 };
    case imageWidth >= 350 && imageWidth < 375:
      return { Single: 9, Double: 17 };
    case imageWidth >= 375 && imageWidth < 400:
      return { Single: 11, Double: 19 };
    case imageWidth >= 400 && imageWidth < 425:
      return { Single: 11, Double: 19 };
    case imageWidth >= 425 && imageWidth < 450:
      return { Single: 11, Double: 19 };
    case imageWidth >= 450 && imageWidth < 475:
      return { Single: 11, Double: 19 };
    case imageWidth >= 475 && imageWidth < 500:
      return { Single: 13, Double: 21 };
    case imageWidth >= 500 && imageWidth < 525:
      return { Single: 13, Double: 21 };
    case imageWidth >= 525 && imageWidth < 550:
      return { Single: 13, Double: 21 };
    case imageWidth >= 550 && imageWidth < 575:
      return { Single: 13, Double: 21 };
    case imageWidth >= 575 && imageWidth < 600:
      return { Single: 15, Double: 23 };
    case imageWidth >= 600 && imageWidth < 625:
      return { Single: 15, Double: 23 };
    case imageWidth >= 625 && imageWidth < 650:
      return { Single: 15, Double: 23 };
    case imageWidth >= 650 && imageWidth < 675:
      return { Single: 15, Double: 23 };
    case imageWidth >= 675 && imageWidth < 700:
      return { Single: 17, Double: 25 };
    case imageWidth >= 700 && imageWidth < 725:
      return { Single: 17, Double: 25 };
    case imageWidth >= 725 && imageWidth < 750:
      return { Single: 17, Double: 25 };
    case imageWidth >= 750 && imageWidth < 775:
      return { Single: 17, Double: 25 };
    case imageWidth >= 775 && imageWidth < 800:
      return { Single: 19, Double: 27 };
    case imageWidth >= 800 && imageWidth < 825:
      return { Single: 19, Double: 27 };
    case imageWidth >= 825 && imageWidth < 850:
      return { Single: 19, Double: 27 };
    case imageWidth >= 850 && imageWidth < 875:
      return { Single: 19, Double: 27 };
    case imageWidth >= 875 && imageWidth < 900:
      return { Single: 21, Double: 29 };
    case imageWidth >= 900 && imageWidth < 925:
      return { Single: 21, Double: 29 };
    case imageWidth >= 925 && imageWidth < 950:
      return { Single: 45, Double: 60 };
    case imageWidth >= 950 && imageWidth < 975:
      return { Single: 55, Double: 50 };
    case imageWidth >= 975 && imageWidth < 1000:
      return { Single: 30, Double: 60 };
    case imageWidth >= 1000:
      return { Single: 30, Double: 50 };
    default:
      return { Single: 5, Double: 7 };
  }
}

/**
 * This function will take Image width as input and Distance in pixel for Distal and Meisal
 * Used to draw label on teeth (insteat of sides).
 * This function will help to adjust position of label that draw on LL and UL Quadrant
 * @param {number} imageWidth
 */
export function zoomToPixelLeftQuadrant(imageWidth: number) {
  switch (true) {
    case imageWidth < 100:
      return { Single: 6, Double: 10 };
    case imageWidth >= 100 && imageWidth < 125:
      return { Single: 7, Double: 7 };
    case imageWidth >= 125 && imageWidth < 150:
      return { Single: 9, Double: 11 };
    case imageWidth >= 150 && imageWidth < 175:
      return { Single: 7, Double: 7 };
    case imageWidth >= 175 && imageWidth < 200:
      return { Single: 7, Double: 10 };
    case imageWidth >= 200 && imageWidth < 225:
      return { Single: 7, Double: 7 };
    case imageWidth >= 225 && imageWidth < 250:
      return { Single: 7, Double: 8 };
    case imageWidth >= 250 && imageWidth < 275:
      return { Single: 8, Double: 15 };
    case imageWidth >= 275 && imageWidth < 300:
      return { Single: 16, Double: 15 };
    case imageWidth >= 300 && imageWidth < 325:
      return { Single: 18, Double: 17 };
    case imageWidth >= 325 && imageWidth < 350:
      return { Single: 18, Double: 17 };
    case imageWidth >= 350 && imageWidth < 375:
      return { Single: 18, Double: 17 };
    case imageWidth >= 375 && imageWidth < 400:
      return { Single: 20, Double: 19 };
    case imageWidth >= 400 && imageWidth < 425:
      return { Single: 20, Double: 19 };
    case imageWidth >= 425 && imageWidth < 450:
      return { Single: 20, Double: 19 };
    case imageWidth >= 450 && imageWidth < 475:
      return { Single: 20, Double: 19 };
    case imageWidth >= 475 && imageWidth < 500:
      return { Single: 22, Double: 21 };
    case imageWidth >= 500 && imageWidth < 525:
      return { Single: 22, Double: 21 };
    case imageWidth >= 525 && imageWidth < 550:
      return { Single: 22, Double: 21 };
    case imageWidth >= 550 && imageWidth < 575:
      return { Single: 24, Double: 21 };
    case imageWidth >= 575 && imageWidth < 600:
      return { Single: 24, Double: 23 };
    case imageWidth >= 600 && imageWidth < 625:
      return { Single: 24, Double: 23 };
    case imageWidth >= 625 && imageWidth < 650:
      return { Single: 24, Double: 23 };
    case imageWidth >= 650 && imageWidth < 675:
      return { Single: 24, Double: 23 };
    case imageWidth >= 675 && imageWidth < 700:
      return { Single: 26, Double: 25 };
    case imageWidth >= 700 && imageWidth < 725:
      return { Single: 26, Double: 25 };
    case imageWidth >= 725 && imageWidth < 750:
      return { Single: 26, Double: 25 };
    case imageWidth >= 750 && imageWidth < 775:
      return { Single: 26, Double: 25 };
    case imageWidth >= 775 && imageWidth < 800:
      return { Single: 28, Double: 27 };
    case imageWidth >= 800 && imageWidth < 825:
      return { Single: 30, Double: 60 };
    case imageWidth >= 825 && imageWidth < 850:
      return { Single: 30, Double: 50 };
    case imageWidth >= 850 && imageWidth < 875:
      return { Single: 30, Double: 60 };
    case imageWidth >= 875 && imageWidth < 900:
      return { Single: 30, Double: 60 };
    case imageWidth >= 900 && imageWidth < 925:
      return { Single: 30, Double: 60 };
    case imageWidth >= 925 && imageWidth < 950:
      return { Single: 30, Double: 60 };
    case imageWidth >= 950 && imageWidth < 975:
      return { Single: 30, Double: 50 };
    case imageWidth >= 975 && imageWidth < 1000:
      return { Single: 30, Double: 60 };
    case imageWidth >= 1000:
      return { Single: 30, Double: 50 };
    default:
      return { Single: 6, Double: 11 };
  }
}

/**
 * This function will take Image width as input and return padding top in pixel
 * Used to prevant image boundry touch of line label
 * @param {number} imageWidth
 */
export function getPaddingTop(imageWidth: number) {
  if (imageWidth < 500) return 5;
  else if (imageWidth >= 500 && imageWidth <= 1000) return 10;
  else return 18;
}

/**
 * Get polygonId property value from the Fabric Object data property
 *
 * @param obj The data prop value
 * @returns String
 */
export function getPolygonIdFromPolygonData(obj: any): string {
  if (obj) {
    if (isObject(obj.data) && "polygonId" in obj.data) {
      const { polygonId }: any = obj.data;
      return polygonId;
    }
    return obj.data || "";
  }
  return "";
}

export function getConvertedOverjetReviewCode(overjetReviewCode?: string) {
  let codeRequiredTranslation = ["DR", "DG", "D", "I", "IP", "CR"];
  let code: string = overjetReviewCode?.replace(/[0-9]/g, "") || "";
  if (codeRequiredTranslation.includes(code)) return "CR";

  return overjetReviewCode;
}

/**
 * Convert result code to result word
 * @param result
 */
export function getReviewResult(result?: string) {
  switch (result) {
    case "A":
      return "Accept";
    case "D":
      return "Deny";
    case "CR":
      return "Clinical Review";
    case "R":
      return "Review";
    case "P":
      return "Pend";
    case "PD":
      return "Partial Deny";
    case "PA":
      return "Partial Accept";
    case "I":
      return "RFI";
    default:
      return result;
  }
}

/**
 * Util function will be used to determine color class according to review result
 * @param {string} reviewResult - The overjet review result
 * @returns
 */
export function getColorClassFromOJRR(reviewResult?: string) {
  let colorClass = "";
  switch (reviewResult) {
    case "D":
      colorClass = "deny";
      break;
    case "CR":
      colorClass = "clinicalReview";
      break;
    case "A":
      colorClass = "accept";
      break;
    case "PD":
      colorClass = "partialDeny";
      break;
    case "P":
      colorClass = "pend";
      break;
    case "R":
      colorClass = "review";
      break;
    case "I":
      colorClass = "RFI";
      break;
    case "PA":
      colorClass = "partialAccept";
      break;
    case "release":
      colorClass = "release";
      break;
    case "route":
      colorClass = "route";
      break;
    default:
      colorClass = "deny";
  }
  return colorClass;
}

/**
 * Util function will be used to determine color code according to review result
 * @param {string} reviewResult - The overjet review result
 * @param {string} polygonType - The polygon type
 * @returns
 */
export function getColorMatchedWithResult(
  reviewResult?: string,
  polygonType?: string,
  pendCode?: string
) {
  const colorDecisionField = userService.getColorDecisionField();
  if (polygonType) {
    return LABEL_COLORS[polygonType];
  }
  let color = "";
  let result = reviewResult;
  if (colorDecisionField && colorDecisionField === "code" && pendCode) {
    if (["X1", "X5", "X8"].includes(pendCode)) {
      result = pendCode;
    } else {
      result = pendCode.replace(/[0-9]/g, "");
    }
    // Guardian only
    if (result === "D" || result === "I") return "180, 152, 255";
  }
  switch (result) {
    case "D":
      color = "255, 134, 132";
      break;
    case "CR":
    case "DR":
    case "DG":
    case "X1":
    case "IP":
      color = "180, 152, 255";
      break;
    case "A":
    case "AR":
      color = "168, 236, 171";
      break;
    case "PD":
      color = "241, 222, 174";
      break;
    case "P":
    case "X5":
    case "X8":
      color = "224, 175, 158";
      break;
    case "R":
      color = "128, 198, 255";
      break;
    case "PA":
      color = "241, 222, 174";
      break;
    case "I":
      color = "143,216,203";
      break;
    default:
      color = "244, 166, 35";
  }
  return color;
}

/**
 * Normalize Bridge Object
 * @param {Object} obj
 * @param {String} tooth
 * @param {Object} geometry
 * @returns Object
 */

export const normalizeBridgeObject = (
  obj: BridgeI,
  tooth: ToothNumberObject,
  geometry: Array<Geometry>
) => {
  return {
    class_type: "Restorative",
    geometry_type: "polygon",
    geometry: [geometry],
    tooth_number: [[String(tooth)]],
    metaclass_type: obj.metaclass_type,
    prediction_size_params: obj.prediction_size_params,
    id: obj.id,
  };
};

export const bridgeProcessing = (
  prediction: ImagePredictionI,
  polygons: FindingI,
  claimToothNumber?: string | number
) => {
  if (
    prediction.bridge &&
    prediction.bridge.Bridge &&
    prediction.bridge.Bridge.tooth_number
  ) {
    let toothIndex = -1;
    const tooth = prediction.bridge.Bridge.tooth_number.find(
      (item: ToothNumberObject, index: number) => {
        const toothNumber = Array.isArray(item)
          ? Number(item[0])
          : Number(item);
        if (toothNumber === Number(claimToothNumber)) {
          toothIndex = index;
          return true;
        }
        return false;
      }
    );
    if (tooth && toothIndex > -1) {
      const toothMaskObj = normalizeBridgeObject(
        prediction.bridge.Bridge,
        tooth,
        prediction.bridge.Bridge.geometry[toothIndex]
      );
      let label = flatMap(
        prediction.bridge.Bridge.bridge_subcategory,
        (tooths) => {
          return tooths.map((tooth: any) => tooth);
        }
      ).find((toothObj) => {
        const key = Object.keys(toothObj).pop();
        if (Number(tooth) === Number(key)) {
          return true;
        }
        return false;
      });
      label = Object.values(label).pop() === "retainer" ? "Retainer" : "Pontic";
      const labelColor =
        label === "Retainer"
          ? LABEL_COLORS["Retainer"]
          : LABEL_COLORS["Pontic"];
      if (toothMaskObj && !isEmpty(toothMaskObj)) {
        let toothMastObjData = toothMaskObj;
        toothMastObjData.metaclass_type = "Findings";
        polygons[`${claimToothNumber} ${label}`] = {
          ...toothMastObjData,
          color: labelColor,
          parentObjectName: label,
        };
      }
    }
  }
};

/**
 * Processing Pontic And mplant
 * @param {ImagePredictionI} prediction
 * @returns polygons object
 */

export const processingPonticAndImplant = (
  prediction: ImagePredictionI
): FindingI => {
  let polygons: FindingI = {};
  if (
    prediction.classifications &&
    prediction.classifications.image_type === "loading"
  ) {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }
  if (
    prediction.classifications &&
    prediction.classifications.image_type === "source"
  ) {
    polygons[SOURCE_IMAGES] = { class_type: "Info" };
    return polygons;
  }
  // TODO: Prashanth 2020-08-04: This is a temporary check to show images without any predictions
  if (
    (prediction.classifications &&
      prediction.classifications.image_type === "na") ||
    !prediction.tooth_number ||
    !Object.keys(prediction.tooth_number).length
  ) {
    polygons[NO_PREDICTION_POSSIBLE] = { class_type: "Info" };
    return polygons;
  }
  if (
    prediction.classifications &&
    prediction.classifications.image_type === "Loading"
  ) {
    polygons[LOADING] = { class_type: "Info" };
    return polygons;
  }
  return {};
};

/**
 * Get correct icon based on overjet review result
 * @param result
 */
export const getOJRRIcon = (result?: string) => {
  switch (result) {
    case "A":
      return faCircleCheck;
    case "D":
      return faCircleXmark;
    case "CR":
      return faTooth;
    case "P":
      return faHourglassHalf;
    case "R":
      return faClock;
    case "PA":
      return faCircleHalfStroke;
    case "PD":
      return faCircleHalfStroke;
    case "I":
      return faCircleQuestion;
    default:
      return faTooth;
  }
};

/**
 * Get tooth or quadrant based on the procedure code provided
 * @param procedure The procedure to check
 * @returns string
 */
export function getToothOrQuadrantKey(serviceLine: ServiceLineObject): string {
  if (serviceLine.procedure) {
    let annotationDisplaRules = userService.getAnnotationDisplayRules() || [];
    const findingRules = extractAnnotationDisplayRules(
      annotationDisplaRules,
      serviceLine.procedure
    );
    if (findingRules?.submissionType === "Quadrant") {
      return "Quadrant";
    } else if (findingRules?.submissionType === "Tooth") {
      return "Tooth Number";
    } else if (serviceLine.toothNumber) return "Tooth Number";
    else if (serviceLine.quadrant) return "Quadrant";
  }
  return "";
}

/**
 * Get the quadrant or tooth value based on the procedure code
 * @param serviceLine The service line object
 * @returns
 */
export function getToothOrQuadrantValue(
  serviceLine: ServiceLineObject
): string | undefined {
  if (serviceLine) {
    let annotationDisplaRules = userService.getAnnotationDisplayRules() || [];
    const findingRules = extractAnnotationDisplayRules(
      annotationDisplaRules,
      serviceLine.procedure
    );
    if (findingRules?.submissionType === "Quadrant" && serviceLine.quadrant) {
      return serviceLine.quadrant;
    } else if (
      findingRules?.submissionType === "Tooth" &&
      (serviceLine.toothNumber || serviceLine.claimTooth)
    ) {
      return serviceLine.toothNumber
        ? serviceLine.toothNumber
        : serviceLine.claimTooth;
    } else {
      if (serviceLine.toothNumber) return serviceLine.toothNumber;
      else if (serviceLine.quadrant) return serviceLine.quadrant;
    }
  }
  return "";
}

/**
 * Check reviewer action if the procedure is reviewable or not
 * @param reviewer
 * @param activeServiceLine
 * @param permissionKeys
 * @param roles
 * @param stateLicenses
 * @param canvasInformation
 */
export function checkReviewerAction(
  reviewer: ReviewerConfig,
  activeServiceLine: ServiceLineObject,
  permissionKeys: Array<string>,
  roles: Array<string>,
  stateLicenses: GenericObject,
  canvasInformation?: CanvasInformationObject
): boolean | null {
  if (
    !shouldButtonVisible(
      reviewer,
      activeServiceLine,
      permissionKeys,
      roles,
      canvasInformation
    )
  ) {
    return null;
  }
  if (
    stateLicenseCheck(
      reviewer,
      activeServiceLine,
      stateLicenses,
      roles,
      canvasInformation
    )
  ) {
    return null;
  }
  if (isClaimReadable(reviewer, activeServiceLine)) {
    return null;
  }
  return true;
}

/**
 * Apply filters to Canvas background image
 * @param fabricCanvas - The fabric canvas object
 * @param type - The type of filter
 * @param value - The value of filter
 */

export const applyFilterToCanvasBG = (
  fabricCanvas: FabricTypes.Canvas,
  type: "brightness" | "contrast" | "reset",
  value?: number
) => {
  const image = fabricCanvas.backgroundImage;
  if (image && typeof image === "object" && image.filters) {
    image.filters = image.filters.map((filterBase) => {
      const filterType = String(filterBase.toObject().type).toLowerCase();
      if (type === filterType) {
        filterBase.setOptions({
          [filterType]: value === 0 ? 0 : (value || 1) / 100,
        });
      }
      if (type === "reset") {
        filterBase.setOptions({
          [filterType]: 0,
        });
      }
      return filterBase;
    });
    image.applyFilters();
    fabricCanvas.renderAll();
  }
};

export const canImageMove = () => cacheService.getItemsSync("moveImage");
export const enableImageMovement = () =>
  cacheService.setItemSync("moveImage", "1");
export const disableImageMovement = () =>
  canImageMove() ? cacheService.removeItem("moveImage") : false;

export function filterWisdomTeeth(teethObject: any, skip: boolean = true) {
  if (skip) return;
  for (const key in teethObject) {
    if (wisdomTeeth.includes(Number(key))) {
      delete teethObject[key];
    }
  }
}

/**
 * Sorts an array of predictions based on a selected image and its match.
 *
 * @param  Array of prediction objects.
 * @returns A sorted array of prediction objects with the selected image's match at the beginning.
 */

export function sortClickedPrediction(predictions: any[]) {
  const currentSelectedImage = cacheService.getItemsSync(
    "currentSelectedImage"
  );

  if (!currentSelectedImage) {
    return predictions;
  }

  const selectedPrediction = predictions.find((prediction) => {
    if (prediction.imageUri) {
      const image = extractOnlyImageName(prediction.imageUri);
      return image === currentSelectedImage;
    }
    return false;
  });

  if (selectedPrediction) {
    const filteredPredictions = predictions.filter(
      (prediction) => prediction.uniqueId !== selectedPrediction.uniqueId
    );
    return [selectedPrediction, ...filteredPredictions];
  }

  return predictions;
}

/**
 * Sorts an array of prediction objects based on a provided canvas information object's sortedSourceImageMap.
 *
 * @param predictions - The array of prediction objects to be sorted.
 * @param canvasInformation - The canvas information object containing the sorting pattern.
 * @returns The sorted array of prediction objects.
 */

export const sortSourceImage = (
  predictions: Array<PredictionObject>,
  canvasInformation?: CanvasInformationObject
) => {
  if (predictions.length === 0 || !canvasInformation?.sortedSourceImageMap)
    return predictions;

  const emptyObjIndex = predictions.findIndex((prediction: PredictionObject) =>
    isEmpty(prediction)
  );

  if (emptyObjIndex === -1) {
    return predictions;
  }

  const sortPattern: Record<string, string> =
    canvasInformation.sortedSourceImageMap;
  const allAttachments = predictions.filter(
    (_, index) => index > emptyObjIndex
  );
  const sortedAttachments: Array<PredictionObject> = [];
  const attachmentsSet = new Set(allAttachments.map((item) => item.uniqueId));

  Object.values(sortPattern).forEach((imageKey: string) => {
    const foundItem = allAttachments.find(
      (item) => item.sourceImages && item.imageUri?.includes(imageKey)
    );
    if (foundItem) {
      sortedAttachments.push(foundItem);
      attachmentsSet.delete(foundItem.uniqueId);
    }
  });

  const finalPredictions = [
    ...predictions.slice(0, emptyObjIndex + 1),
    ...sortedAttachments,
    ...predictions.filter((item) => attachmentsSet.has(item.uniqueId)),
  ];

  return finalPredictions;
};

/**
 * Sorts an array of prediction objects based on a specified procedure code and canvas information.
 *
 * @param predictions - The array of prediction objects to be sorted.
 * @param procedureCode - The procedure code for sorting.
 * @param canvasInformation - The canvas information for sorting.
 * @returns The sorted array of prediction objects.
 */

export const sortCroppedImages = (
  predictions: Array<PredictionObject>,
  procedureCode?: string,
  canvasInformation?: CanvasInformationObject
) => {
  if (predictions.length === 0 || !canvasInformation?.sortedCroppedImageMap)
    return predictions;

  const emptyObjIndex = predictions.findIndex((prediction: PredictionObject) =>
    isEmpty(prediction)
  );

  if (emptyObjIndex === 0) {
    return predictions;
  }

  if (canvasInformation?.sortedCroppedImageMap && procedureCode) {
    let allMatchedImages: PredictionObject[] = [];

    if (canvasInformation?.sortedCroppedImageMap && procedureCode) {
      const [allCroppedImages, remainingPredictions] = partition(
        predictions,
        (_, index) => index < emptyObjIndex
      );

      const sortPattern: Record<string, string> =
        canvasInformation.sortedCroppedImageMap[procedureCode] || {};
      if (isEmpty(sortPattern)) return predictions;
      Object.keys(sortPattern).forEach((number) => {
        const [matchingImages, remainingImages] = partition(
          allCroppedImages,
          (item) => {
            if (item.imageUri)
              return item.imageUri.includes(sortPattern[number]);
            return false;
          }
        );
        if (matchingImages.length > 0) allMatchedImages.push(...matchingImages);
        allCroppedImages.splice(0, allCroppedImages.length, ...remainingImages);
      });

      return [...allMatchedImages, ...remainingPredictions];
    }
    return predictions;
  }
  return predictions;
};

/**
 * Partitions an array into two based on a specified predicate.
 *
 * @template T
 * @param array - The array to be partitioned.
 * @param predicate - The predicate function for partitioning.
 * @returns An array containing two elements: matched items and remaining items.
 */
export const partition = <T>(
  array: T[],
  predicate: (item: T, index: number) => boolean
): [T[], T[]] => {
  const matched: T[] = [];
  const remaining: T[] = [];

  array.forEach((item, index) => {
    if (predicate(item, index)) {
      matched.push(item);
    } else {
      remaining.push(item);
    }
  });

  return [matched, remaining];
};

/**
 * Check if isSupportedProcedure is available in the service line
 * object and return its value otherwise return true
 *
 * @param serviceLine The service line object
 * @returns boolean
 */
export function isSupportedProcedure(serviceLine: ServiceLineObject) {
  if (isObject(serviceLine) && "isSupportedProcedure" in serviceLine) {
    return serviceLine.isSupportedProcedure;
  }
  return true;
}
